<template>
  <v-overlay :value="show">
    <v-card
      width="1300px"
      light
      class="pb-5"
      color="#333333"
    >
      <v-card-title class="white--text d-flex justify-space-between">
        <h3>Формирование заявки СО</h3>
        <v-btn
          color="error"
          small
          @click="close"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <div class="white pa-5 rounded-0">
        <v-row>
          <v-col cols="3">
            <label>Перевозчик</label>
            <v-text-field
              v-model="form.carrier_name"
              outlined
              dense
              hide-details="auto"
            />
          </v-col>
          <v-col cols="3">
            <label>Перевозчик УНП(ИНН)</label>
            <v-text-field
              v-model="form.carrier_inn"
              outlined
              dense
              hide-details="auto"
            />
          </v-col>
          <v-col cols="3">
            <label>Пункт ввоза</label>
            <v-autocomplete
              v-model="dispatchCustomsCode"
              auto-select-first
              outlined
              dense
              hide-details="auto"
              background-color="white"
              item-text="text"
              item-value="code"
              :items="customsPoints"
              :filter="filterBySearchField"
            />
          </v-col>
          <v-col cols="3">
            <label>Дата прибытия в пункт ввоза</label>
            <custom-date-picker
              v-model="form.arrival_date"
              outlined
              dense
              hide-details="auto"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="3">
            <label>Транспортное средство №1</label>
            <v-text-field
              v-model="truckNumber"
              outlined
              dense
              hide-details="auto"
            />
          </v-col>
          <v-col cols="3">
            <label>Марка ТС</label>
            <v-autocomplete
              ref="transport_mark_code"
              v-model="truckBrand"
              item-text="name"
              item-value="name"
              :items="nsiTransportMarks"
              auto-select-first
              background-color="white"
              dense
              outlined
              hide-details="true"
            />
          </v-col>
          <v-col cols="3">
            <label>Транспортное средство №2</label>
            <v-text-field
              v-model="trailerNumber"
              outlined
              dense
              hide-details="auto"
            />
          </v-col>
          <v-col cols="3">
            <label>Марка ТС</label>
            <v-autocomplete
              ref="transport_mark_code"
              v-model="trailerBrand"
              item-text="name"
              item-value="name"
              :items="nsiTransportMarks"
              auto-select-first
              background-color="white"
              dense
              outlined
              hide-details="true"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="4">
            <label>Место доставки (СВХ)</label>
            <v-autocomplete
              v-model="destinationPtoCode"
              auto-select-first
              outlined
              dense
              hide-details="auto"
              background-color="white"
              item-text="text"
              item-value="code"
              :items="customsPoints"
              :filter="filterBySearchField"
            />
          </v-col>
          <v-col cols="4">
            <label>Таможня назначения</label>
            <v-text-field
              v-model="form.destination_customs_name"
              outlined
              dense
              hide-details="auto"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <label>Дополнительная информация</label>
            <v-textarea
              v-model="form.add_info"
              outlined
              dense
              hide-details="auto"
              rows="3"
            />
          </v-col>
        </v-row>
        <div class="d-flex justify-end mt-4">
          <v-btn
            color="#5CB7B1"
            elevation="0"
            darks
            @click="submit"
          >
            Cформировать
          </v-btn>
        </div>
      </div>
    </v-card>
  </v-overlay>
</template>
<script>


import {mapGetters} from "vuex";
import CustomDatePicker from "@/components/shared/custom-date-picker.vue";
import modalWindowMixin from "@/mixins/modal-window.mixin";
import {downloadFile, getFilenameFromContentDisposition} from "@/helpers/documents";
import {filterBySearchFieldMixin} from "@/mixins/catalogs";
import moment from "moment/moment";
import {getCustomsNameByCode} from "@/helpers/catalogs";

export default {
  components: {CustomDatePicker},
  mixins:[modalWindowMixin, filterBySearchFieldMixin],
  props: {
    show: {
      required: true,
      type: Boolean,
    }
  },
  data() {
    return {
      truckNumber:null,
      truckBrand:null,
      trailerNumber:null,
      trailerBrand:null,
      dispatchCustomsCode:null,
      destinationPtoCode:null,
      form: {
        carrier_name: "",
        carrier_inn: "",
        dispatch_customs_name: "",
        arrival_date: "",
        destination_pto_name: "",
        destination_customs_name: "",
        add_info: ""
      }
    }
  },
  computed: {
    ...mapGetters({
      selected: 'transit/getSelected',
      shipmentIndex:'transit/getShipmentIndex',
      nsiTransportMarks: "transit/getNsiTransportMarks",
      customsPoints: "catalogs/getNsiCustomsPoints",
    })
  },
  watch: {
    show: {
      handler(value){
        if (value) {
          this.setFields()
        }
      },
      immediate:true
    }
  },
  methods: {
    getShipment(){
      return this.selected.ware_shipments[this.shipmentIndex] ?? null
    },
    setFields() {
      const shipment = this.getShipment()
      const {carrier, transports, registration_details} = this.selected
      const {destination_customs_code} = shipment.destination_customs.destination_customs_json
      const {dispatch_customs_code} = registration_details
      const [truck, trailer] = transports
      this.truckBrand = truck?.transport_mark_name
      this.truckNumber = truck?.number
      this.trailerBrand = trailer?.transport_mark_name
      this.trailerNumber = trailer?.number
      this.dispatchCustomsCode = dispatch_customs_code
      this.destinationPtoCode = destination_customs_code
      this.form = {
        ...this.form,
        carrier_name: carrier.carrier_name,
        carrier_inn: carrier.carrier_inn,
        arrival_date: moment().format('YYYY-MM-DD'),
      }
    },
    submit(){
      const payload = {
        ...this.form,
        transport_mark_names:[this.truckBrand, this.trailerBrand],
        transport_numbers:[this.truckNumber, this.trailerNumber],
        dispatch_customs_name: getCustomsNameByCode(this.customsPoints, this.dispatchCustomsCode),
        destination_pto_name: getCustomsNameByCode(this.customsPoints, this.destinationPtoCode)
      }
      this.$store.dispatch('transit/getWarrantyApplication', {
        declarationId:this.selected.id,
        payload
      }).then((res) => {
        downloadFile({
          data:res.data,
          type:"application/pdf",
          name:getFilenameFromContentDisposition(res)
        })
      }).catch(() => this.$error())
    }
  }
}
</script>
